import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import { PageContent } from '../components/PageContent'
import Container from '../components/Container'
import styled from 'styled-components'
import { breakpoint } from '../styles/variables'
import { Script } from 'gatsby'

const StyledContainer = styled(Container)`
    max-width: 700px;
    padding: 1.5rem 1rem 2rem;

    @media ${breakpoint.sm} {
        padding: 0 1rem 2rem;
    }

    @media ${breakpoint.md} {
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        padding: 0;
    }

    @media ${breakpoint.lg} {
        margin-top: 4rem;
        margin-bottom: 5rem;
    }

    p,
    li,
    h3 {
        font-size: 0.875rem;
        line-height: 1.5;
    }

    a {
        color: #000000;
    }
`

const OrderedList = styled.ol`
    list-style-type: upper-roman;

    li {
        margin: 1.5rem 0;

        &::marker {
            font-size: 1rem;
        }
    }

    h2 {
        font-size: 1rem;
    }
`

const UnorderedList = styled.ul`
    list-style-type: disc;
    padding-left: 2rem;

    li {
        margin: 0.5em 0;

        ul {
            list-style-type: circle;
            padding-left: 2rem;
        }
    }
`

const StyledUl = styled(UnorderedList)`
    h3, p {
        display: inline;
    }
`

const NoBulletUl = styled(UnorderedList)`
    list-style-type: none;

    h3 {
        margin-bottom: 0.3em;
    }

    p {
        margin: 0;
    }
`


const CookiesDeclaration = () => {
    return (
        <>
            <SmallBanner title="Deklarace cookies" isLongTitle={true}/>
            <PageContent>
                <StyledContainer>

                {/* <Script id="CookieDeclaration" src="https://consent.cookiebot.com/e1544ab2-6d93-4353-acb5-83ffb7ee76c5/cd.js" type="text/javascript" async></Script> */}
                </StyledContainer>
            </PageContent>

        </>
    )
}

export default CookiesDeclaration

export const Head = () => (
    <>
      <title>Zásady zpracování osobních údajů | Cestu-j</title>
      <meta name="description" content="Cestovní itineráře na míru" />
    </>
  )