import * as React from 'react'
import styled from 'styled-components'
import { breakpoint, navPosition } from '../styles/variables'
import bannerBackground from '../assets/images/cover-small.jpg'
import { StaticImage } from 'gatsby-plugin-image'

const Wrapper = styled.header`
  display: grid;
  height: 5rem;

  @media ${breakpoint.lg} {
    height: ${navPosition};
    margin-bottom: 70px;
  }

  .banner-image {
    grid-area: 1/1;

  }
`
const BannerContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 1.5rem 1rem;
  background: linear-gradient(0deg, rgba(0,0,0,0.8337710084033614) 0%, rgba(0,0,0,0.29315476190476186) 100%);
  grid-area: 1/1;
  z-index: 2;
  width: 100%;
  color: #ffffff;

  @media ${breakpoint.sm} {
    padding: 2rem 1.5rem;
  }

  @media ${breakpoint.lg} {
    padding: 2rem 4rem;
  }
  @media ${breakpoint.xl} {
    padding: 2rem 8rem;
  }
`

const Title = styled.h1 `
  margin: 0;
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: .2em;

  @media ${breakpoint.md} {
    font-size: 2.5rem;
  }

  @media ${breakpoint.xxl} {
    padding-left: 4rem;
  }

  ${({isLongTitle}) => `${isLongTitle && `
    font-size: 1.5rem;
    line-height: 1.5;

    @media ${breakpoint.md} {
      font-size: 1.5rem;
    }

    @media ${breakpoint.lg} {
      max-width: 40%;
    }
    @media ${breakpoint.xl} {
      font-size: 2rem;
    }
`}`}


`


const SmallBanner = ({title, className, isLongTitle}) => {
  return (
    <Wrapper className={className}>
      <StaticImage className="banner-image"  alt="" src={"../assets/images/cover-small.jpg"}/>
      <BannerContainer>
        <Title isLongTitle={isLongTitle}>{title}</Title>
      </BannerContainer>
    </Wrapper>
  )
}

export default SmallBanner