import styled from 'styled-components'
import { breakpoint } from '../styles/variables'

export const PageContent = styled.main`
    min-height: calc(100vh - 400px);
    padding-top: 2rem;

    @media ${breakpoint.md} {
        padding-top: 3rem;
    }
`